@use "base";

.font{
  &-primary{
    color: base.$primary-color;
  }
}

.nav-link {
  color: base.$primary-color;
  &:hover {
    color: base.$placeholder-primary-color;
  }
}

.btn-link {
  &:hover {
    color: base.$placeholder-primary-color;
  }
  &:active, &:focus {
    color: base.$placeholder-primary-color;
  }
}

.regbtn {
  font-weight: base.$font-weightbtn;
  color: base.$primary-color;
  background-color: base.$secondary-color;
  border-color: base.$primary-color;
}

.delbtn {
  background-color: base.$button-color;
  font-weight: base.$font-weightbtn;
  color: base.$delete-color;
  border-color: base.$delete-color;
}

.btn{
  &-delete{
    width: 100px;
    height: 40px;
  }
}

.cardLink {
  color: base.$primary-color;
  &:hover,
  &:focus,
  &:active {
    color: base.$primary-color;
  }
}

.normalbtn {
  background-color: base.$primary-color;
  color: base.$secondary-color;
  font-weight: base.$font-weightbtn;
}

.form-control,
.form-select
{
  background-color: base.$ternary-color !important;
  color: base.$primary-color !important;
  outline: none !important;
  border: none !important;
}

.form-control:focus,
.form-select:focus {
  border-color: base.$primary-color;
  box-shadow: 0 0 0 0.2rem rgba(204, 175, 165, 0.5);
}

.btn:focus {
  border-color: base.$primary-color;
  box-shadow: 0 0 0 0.2rem rgba(204, 175, 165, 0.5);
}

.btn:active,
.btn:hover {
  background-color: base.$primary-color;
  color: base.$placeholder-primary-color;
}

.btn-outline-danger,
.delbtn {
  &:active,
  &:hover {
    background-color: base.$delete-color;
    color: base.$placeholder-primary-color;
  }
}
.page {
  width: 80%;
  margin: 0 auto;
}

// Register  & login
.register {
  background-color: base.$secondary-color;
  color: base.$primary-color;
  width: 80%;
}

.link {
  color: base.$primary-color;
  text-decoration-color: base.$primary-color;
  &:focus,
  &:hover,
  &:active {
    color: base.$primary-color;
  }
}


// card designs
.eachCard {
  background-color: #242424;
  border: 0.05em solid base.$primary-color;
  border-radius: 0.35em;
  padding: 0.75em;
  margin-bottom: 0.5em;
}

// habit badge
.habit {
  &-badge {
    background-color: base.$secondary-color;
    color: base.$primary-color;
  }
}

// routines page
.exercise{
  &-card{
    background-color: base.$secondary-color;
    color: base.$primary-color;
  }
  &-badge{
    padding: 0.3em;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    color: base.$primary-color;
    border: 1px solid base.$primary-color;
    
}
}
.close{
  color: base.$primary-color !important;
}

// modify bootstrap list
.list-group-item{
  background-color: base.$secondary-color;
  color: base.$primary-color;
}
.alert-dismissible{
  padding-right: 2rem;
}

.banner {
  &-entry {
    border: 0.01em solid base.$primary-color;
    background-color: base.$secondary-color;
    &-card{
      padding: 0.75em;
      background-color: base.$ternary-color;
      // max-width: 250px;
      border-radius: 0.25rem;
      margin: 0.5em;
    }
  }
  &-routine {
    padding: 0.8rem;
    border: 1px solid base.$primary-color;
    background-color: base.$secondary-color;
    width: auto;
    border-radius: 0.35rem;
  }
  &-sad {
    // background-color: base.$ternary-color;
    padding: 0.2em;
    // color: base.$primary-color;
    border-radius: 0.15em;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.logout {
  color: base.$primary-color;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 0;
  &:hover,
  &:active,
  &:focus {
    color: base.$primary-color !important;
    background-color: transparent;
    box-shadow: none;
    border: none;
    outline: none;
  }
}

.fab-btn {
  position: fixed;
  bottom: 10%;
  right: 10%;
  background-color: base.$primary-color;
  z-index: 99999;
}

.navbar {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .page {
    width: 80%;
    margin: 0 auto;
  }
}
